themeName = 'snow'
activeColor = #06c
borderColor = #ccc
backgroundColor = #fff
inactiveColor = #444
shadowColor = #ddd
textColor = #444

@import './core'
@import './base'
@import './snow/*'

.ql-snow
  a
    color: activeColor

.ql-container.ql-snow
  border: 1px solid borderColor
