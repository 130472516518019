.ql-toolbar.ql-snow
  border: 1px solid borderColor
  box-sizing: border-box
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif
  padding: 8px

  .ql-formats
    margin-right: 15px

  .ql-picker-label
    border: 1px solid transparent
  .ql-picker-options
    border: 1px solid transparent
    box-shadow: rgba(0,0,0,0.2) 0 2px 8px
  .ql-picker.ql-expanded
    .ql-picker-label
      border-color: borderColor
    .ql-picker-options
      border-color: borderColor

  .ql-color-picker
    .ql-picker-item.ql-selected, .ql-picker-item:hover
      border-color: #000

.ql-toolbar.ql-snow + .ql-container.ql-snow
  border-top: 0px;
