tooltipMargin = 8px

.ql-snow
  .ql-tooltip
    background-color: #fff
    border: 1px solid borderColor
    box-shadow: 0px 0px 5px shadowColor
    color: textColor
    padding: 5px 12px
    white-space: nowrap
    &::before
      content: "Visit URL:"
      line-height: 26px
      margin-right: tooltipMargin
    input[type=text]
      display: none
      border: 1px solid borderColor
      font-size: 13px
      height: 26px
      margin: 0px
      padding: 3px 5px
      width: 170px
    a.ql-preview
      display: inline-block
      max-width: 200px
      overflow-x: hidden
      text-overflow: ellipsis
      vertical-align: top
    a.ql-action::after
      border-right: 1px solid borderColor
      content: 'Edit'
      margin-left: tooltipMargin*2
      padding-right: tooltipMargin
    a.ql-remove::before
      content: 'Remove'
      margin-left: tooltipMargin
    a
      line-height: 26px
  .ql-tooltip.ql-editing
    a.ql-preview, a.ql-remove
      display: none
    input[type=text]
      display: inline-block
    a.ql-action::after
      border-right: 0px
      content: 'Save'
      padding-right: 0px
  .ql-tooltip[data-mode=link]::before
    content: "Enter link:"
  .ql-tooltip[data-mode=formula]::before
    content: "Enter formula:"
  .ql-tooltip[data-mode=video]::before
    content: "Enter video:"
