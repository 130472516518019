// Styles necessary for Quill

LIST_STYLE = decimal lower-alpha lower-roman
LIST_STYLE_WIDTH = 1.2em
LIST_STYLE_MARGIN = 0.3em
LIST_STYLE_OUTER_WIDTH = LIST_STYLE_MARGIN + LIST_STYLE_WIDTH
MAX_INDENT = 9

resets(arr)
  unquote('list-' + join(' list-', arr))

.ql-container
  box-sizing: border-box
  font-family: Helvetica, Arial, sans-serif
  font-size: 13px
  height: 100%
  margin: 0px
  position: relative

.ql-container.ql-disabled
  .ql-tooltip
    visibility: hidden

.ql-container:not(.ql-disabled)
  li[data-list=checked],
  li[data-list=unchecked]
    > .ql-ui
      cursor: pointer

.ql-clipboard
  left: -100000px
  height: 1px
  overflow-y: hidden
  position: absolute
  top: 50%
  p
    margin: 0
    padding: 0

.ql-editor
  box-sizing: border-box
  counter-reset: resets(0..MAX_INDENT)
  line-height: 1.42
  height: 100%
  outline: none
  overflow-y: auto
  padding: 12px 15px
  tab-size: 4
  -moz-tab-size: 4
  text-align: left
  white-space: pre-wrap
  word-wrap: break-word
  > *
    cursor: text

  p, ol, pre, blockquote, h1, h2, h3, h4, h5, h6
    margin: 0
    padding: 0
  p, h1, h2, h3, h4, h5, h6
    @supports (counter-set: none)
      counter-set: resets(0..MAX_INDENT)
    @supports not (counter-set: none)
      counter-reset: resets(0..MAX_INDENT)
  table
    border-collapse: collapse
  td
    border: 1px solid #000
    padding: 2px 5px
  ol
    padding-left: 1.5em
  li
    list-style-type: none
    padding-left: LIST_STYLE_OUTER_WIDTH
    position: relative

    > .ql-ui:before
      display: inline-block
      margin-left: -1*LIST_STYLE_OUTER_WIDTH
      margin-right: LIST_STYLE_MARGIN
      text-align: right
      white-space: nowrap
      width: LIST_STYLE_WIDTH

  li[data-list=checked],
  li[data-list=unchecked]
    > .ql-ui
      color: #777

  li[data-list=bullet] > .ql-ui:before
    content: '\2022'
  li[data-list=checked] > .ql-ui:before
    content: '\2611'
  li[data-list=unchecked] > .ql-ui:before
    content: '\2610'

  li[data-list]
    @supports (counter-set: none)
      counter-set: resets(1..MAX_INDENT)
    @supports not (counter-set: none)
      counter-reset: resets(1..MAX_INDENT)

  li[data-list=ordered]
    counter-increment: list-0
    > .ql-ui:before
      content: unquote('counter(list-0, ' + LIST_STYLE[0] + ')') '. '
  for num in (1..MAX_INDENT)
    li[data-list=ordered].ql-indent-{num}
      counter-increment: unquote('list-' + num)
      > .ql-ui:before
        content: unquote('counter(list-' + num + ', ' + LIST_STYLE[num%3] + ')') '. '
    if (num < MAX_INDENT)
      li[data-list].ql-indent-{num}
        @supports (counter-set: none)
          counter-set: resets((num+1)..MAX_INDENT)
        @supports not (counter-set: none)
          counter-reset: resets((num+1)..MAX_INDENT)

  for num in (1..MAX_INDENT)
    .ql-indent-{num}:not(.ql-direction-rtl)
      padding-left: (3*num)em
    li.ql-indent-{num}:not(.ql-direction-rtl)
      padding-left: (3*num + LIST_STYLE_OUTER_WIDTH)em
    .ql-indent-{num}.ql-direction-rtl.ql-align-right
      padding-right: (3*num)em
    li.ql-indent-{num}.ql-direction-rtl.ql-align-right
      padding-right: (3*num + LIST_STYLE_OUTER_WIDTH)em

  li.ql-direction-rtl
    padding-right: LIST_STYLE_OUTER_WIDTH
    > .ql-ui:before
      margin-left: LIST_STYLE_MARGIN
      margin-right: -1*LIST_STYLE_OUTER_WIDTH
      text-align: left

  table
    table-layout: fixed
    width: 100%
    td
      outline: none

  .ql-code-block-container
    font-family: monospace

  .ql-video
    display: block
    max-width: 100%
  .ql-video.ql-align-center
    margin: 0 auto
  .ql-video.ql-align-right
    margin: 0 0 0 auto

  .ql-bg-black
    background-color: rgb(0,0,0)
  .ql-bg-red
    background-color: rgb(230,0,0)
  .ql-bg-orange
    background-color: rgb(255,153,0)
  .ql-bg-yellow
    background-color: rgb(255,255,0)
  .ql-bg-green
    background-color: rgb(0,138,0)
  .ql-bg-blue
    background-color: rgb(0,102,204)
  .ql-bg-purple
    background-color: rgb(153,51,255)

  .ql-color-white
    color: rgb(255,255,255)
  .ql-color-red
    color: rgb(230,0,0)
  .ql-color-orange
    color: rgb(255,153,0)
  .ql-color-yellow
    color: rgb(255,255,0)
  .ql-color-green
    color: rgb(0,138,0)
  .ql-color-blue
    color: rgb(0,102,204)
  .ql-color-purple
    color: rgb(153,51,255)

  .ql-font-serif
    font-family: Georgia, Times New Roman, serif
  .ql-font-monospace
    font-family: Monaco, Courier New, monospace

  .ql-size-small
    font-size: 0.75em
  .ql-size-large
    font-size: 1.5em
  .ql-size-huge
    font-size: 2.5em

  .ql-direction-rtl
    direction: rtl
    text-align: inherit

  .ql-align-center
    text-align: center
  .ql-align-justify
    text-align: justify
  .ql-align-right
    text-align: right

  .ql-ui
    position: absolute

.ql-editor.ql-blank::before
  color: rgba(0,0,0,0.6)
  content: attr(data-placeholder)
  font-style: italic
  left: 15px
  pointer-events: none
  position: absolute
  right: 15px
